<template>
    <div class="view-bookings" v-if="translates && translates[langUrl]">
         <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="userFeedbacks">{{ translates.navigation.button_route_myBookings[lang] }}</div>
        </nav>
        <h1 class="view-bookings__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-bookings__content">
            <template v-for="(booking, i) in bookings">
                <div
                    :key="'booking' + i"
                    class="view-bookings__booking"
                    :class="{'view-bookings__booking-canceled': booking.status === BOOKING_STATUS.CANCELED}">
                    <div class="view-bookings__booking__trip">
                        {{ booking.trip.name }}
                    </div>
                    <v-spacer />
                    <div class="view-bookings__booking__dates">
                        <div class="view-bookings__booking__dates__start">
                            <div class="view-bookings__booking__dates__day">{{ booking.start | moment('dddd') }}</div>
                            {{ booking.start | moment('DD MMM YYYY') }}</div>
                        <img src="../../assets/ArrowCircleRight.svg" />
                        <div class="view-bookings__booking__dates__finish">
                            <div class="view-bookings__booking__dates__day">{{ booking.finish | moment('dddd') }}</div>
                            {{ booking.finish | moment('DD MMM YYYY') }}</div>
                    </div> 
                    <div class="view-bookings__booking__price">
                        <div class="view-bookings__booking__price__value"><span v-html="priceFormated(booking.price, booking.currency)"></span> </div>
                    </div>
                    <div class="view-bookings__booking__info">{{ translates[langUrl].bookingInfo[lang] }}</div>
                    <BaseButton
                        v-if="booking.status !== BOOKING_STATUS.CANCELED"
                        class="button"
                        style="width: 100%"
                        @click="deleteBooking(booking)">{{ translates[langUrl].button_deleteBooking[lang] }}</BaseButton>
                    <div
                        v-if="booking.status === BOOKING_STATUS.CANCELED"
                        class="view-bookings__booking__canceled">{{ translates[langUrl].status_CANCELED[lang] }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS } from '@/vars';
    import { priceFormated, isMobile } from '@/helpers';
    import BaseButton from '../../components/common/BaseButton';

    export default {
        name: 'Bookings',
        metaInfo: {
            title: 'Мои бронирования'
        },
        components: {
            BaseButton
        },
        data: () => ({
            BOOKING_STATUS,
            priceFormated,
            isDesktop: false,
            langUrl: '/user/bookings'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        mounted() {
            store.dispatch('bookings/fetch', {});
            this.onResize();
        },
        methods: {
            async deleteBooking(booking) {
                await store.dispatch('bookings/delete', { id: booking._id });
                await store.dispatch('bookings/fetch', {});
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async userFeedbacks () {
                await this.$router.push({ name: 'user-bookings' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
        }
    }
</script>

<style lang="scss">
    .view-bookings {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }
        }
        &__booking{
            display: flex;
            flex-direction: column;
            padding: 16px 24px 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-bottom: 32px;
            color: #3A4256;

            @media all and (min-width: 768px) {
                width: 326px;
                margin-right: 32px;
                // &:last-of-type {
                //     margin-right: 0;
                // }
            }
            
            &-canceled {
                background: #dddddd;
            }
            &__canceled {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 12px !important;
                border: 1px solid #3A4256 !important;
                margin-bottom: 5px;
                height: 48px;
                align-self: center;
                background: none;
                font-size: 14px;
                line-height: 140%;
            }

            &__trip {
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__user {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.02em;
                margin-bottom: 14px;
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                padding: 16px 0;
                border-top: 1px solid #F6F7F9;
                border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            &__price {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                margin-top: 24px;
            }
            &__info {
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                margin: 12px 0 24px;
            }
        }
    }
</style>